<template>
  <div class="min-h-screen bg-white dark:bg-slate-800">
    <div id="top_border" />
    <VerifyNotice
      v-if="
        $route.name !== 'home' &&
        $route.name !== 'verify-email' &&
        authenticated &&
        user &&
        user.id &&
        !user.email_verified_at &&
        !isAdminPage
      "
      :id="user.id"
    />
    <AppNav v-if="!isAdminPage" />
    <div class="w-full">
      <router-view v-slot="{ Component }" :class="isAdminPage ? '' : 'p-6'">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <AppFooter v-if="!isAdminPage" class="sticky top-[100vh] w-full" />
    <AppToast />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppNav from '@components/AppNav.vue';
import AppFooter from '@components/AppFooter.vue';
import VerifyNotice from '@components/VerifyNotice.vue';
import OctoCat from '@components/OctoCat.vue';

export default {
  components: {
    AppNav,
    VerifyNotice,
    AppFooter,
    OctoCat,
  },
  computed: {
    ...mapGetters({
      darkMode: 'auth/darkMode',
    }),
    currentRouteName() {
      return this.$route.name;
    },
    isAdminPage() {
      if (
        this.currentRouteName == 'admin' ||
        this.currentRouteName == 'roles' ||
        this.currentRouteName == 'permissions' ||
        this.currentRouteName == 'settings' ||
        this.currentRouteName == 'users' ||
        this.currentRouteName == 'app-settings' ||
        this.currentRouteName == 'phpinfo' ||
        this.currentRouteName == 'departments' ||
        this.currentRouteName == 'break-types' ||
        this.currentRouteName == 'attendance' ||
        this.currentRouteName == 'asset-category' ||
        this.currentRouteName == 'asset-management' ||
        this.currentRouteName == 'branches' ||
        this.currentRouteName == 'asset-details' ||
        this.currentRouteName == 'notice' ||
        this.currentRouteName == 'holiday' ||
        this.currentRouteName == 'work-reports' ||
        this.currentRouteName == 'buyers' ||
        this.currentRouteName == 'prices' ||
        this.currentRouteName == 'inventory' ||
        this.currentRouteName == 'inventory-purchases' ||
        this.currentRouteName == 'payments' ||
        this.currentRouteName == 'productreturns' ||
        this.currentRouteName == 'sale' ||
        this.currentRouteName == 'saleitem' ||
        this.currentRouteName == 'shippingAddress' ||
        this.currentRouteName == 'shipment' ||
        this.currentRouteName == 'pendingSale' ||
        this.currentRouteName == 'shipments-details' ||
        this.currentRouteName == 'sale-details' ||
        this.currentRouteName == 'inventory-items'
      ) {
        return true;
      }
      return false;
    },
    showOctoCat() {
      if (
        this.currentRouteName == 'home' ||
        this.currentRouteName == 'about' ||
        this.currentRouteName == 'terms'
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      popToast: 'toast/popToast',
    }),
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
