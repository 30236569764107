// general routes
const Home = () =>
  import(/* webpackChunkName: "js/HomePage" */ '@pages/Home.vue');
const About = () =>
  import(/* webpackChunkName: "js/AboutPage" */ '@pages/About.vue');
const Terms = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@pages/Terms.vue');
const Dashboard = () =>
  import(/* webpackChunkName: "js/DashboardPage" */ '@pages/Dashboard.vue');
const Login = () =>
  import(/* webpackChunkName: "js/LoginPage" */ '@pages/Login.vue');
const ForgotPassword = () =>
  import(
    /* webpackChunkName: "js/ForgotPasswordPage" */ '@pages/ForgotPassword.vue'
  );
const ResetPassword = () =>
  import(
    /* webpackChunkName: "js/ResetPasswordPage" */ '@pages/ResetPassword.vue'
  );
const Register = () =>
  import(/* webpackChunkName: "js/RegisterPage" */ '@pages/Register.vue');
const VerifyEmail = () =>
  import(/* webpackChunkName: "js/VerifyEmailPage" */ '@pages/VerifyEmail.vue');

const Settings = () =>
  import(
    /* webpackChunkName: "js/SettingsPage" */ '@pages/settings/Settings.vue'
  );
const Profile = () =>
  import(
    /* webpackChunkName: "js/ProfilePage" */ '@pages/settings/Profile.vue'
  );
const Password = () =>
  import(
    /* webpackChunkName: "js/PasswordPage" */ '@pages/settings/Password.vue'
  );
const Account = () =>
  import(
    /* webpackChunkName: "js/AccountPage" */ '@pages/settings/Account.vue'
  );
const NotFound = () =>
  import(/* webpackChunkName: "js/NotFoundPage" */ '@pages/NotFound.vue');

const AdminLayout = () =>
  import(
    /* webpackChunkName: "js/AdminLayoutPage" */ '@/layouts/AdminLayout.vue'
  );
const Admin = () =>
  import(/* webpackChunkName: "js/AdminPage" */ '@/views/admin/Admin.vue');
const Users = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/admin/Users.vue');
const Roles = () =>
  import(/* webpackChunkName: "js/RolesPage" */ '@/views/admin/Roles.vue');
const Permissions = () =>
  import(
    /* webpackChunkName: "js/PermissionsPage" */ '@/views/admin/Permissions.vue'
  );
const AppSettings = () =>
  import(
    /* webpackChunkName: "js/AppSettingsPage" */ '@/views/admin/AppSettings.vue'
  );
const Department = () =>
  import(
    /* webpackChunkName: "js/AppSettingsPage" */ '@views/admin/Department.vue'
  );
const Breaktype = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/break-type/Index.vue'
  );
const Branch = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/branch/Index.vue'
  );
const Attendance = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/attendance/Index.vue'
  );

const AssetCategory = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/assetmanagement/category/Index.vue'
  );
const AssetManagement = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/assetmanagement/Index.vue'
  );
const AssetDetails = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/assetmanagement/ViewAsset.vue'
  );
const Notice = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/notice/Index.vue'
  );
const NoticeDetails = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/notice/ViewNotice.vue'
  );
const Holiday = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/holiday/Index.vue'
  );
const Quote = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/quote/Index.vue'
  );
const Feedback = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/feedback/Index.vue'
  );

const LeaveApplication = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/leave/application/Index.vue'
  );

const LeaveType = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/leave/type/Index.vue'
  );
const LeaveManagement = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/leave/Index.vue'
  );
const LeaveManagementDetails = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/leave/Show.vue'
  );
const LeaveRequest = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/leave/request/Index.vue'
  );

const WorkReport = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/work-report/Index.vue'
  );

const InventoryItem = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/inventory/items/Index.vue'
  );
const Inventory = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/inventory/Index.vue'
  );
const PurchaseHistory = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/inventory/History.vue'
  );
const Buyer = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/buyer/Index.vue'
  );
const Price = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/price/Index.vue'
  );
const Payment = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/payment/Index.vue'
  );
const ProductReturn = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/productreturn/Index.vue'
  );
const Sale = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/sale/Index.vue'
  );
const SaleDetails = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/sale/ViewSale.vue'
  );

const PendingSale = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/sale/Pending.vue'
  );
const SaleItem = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/saleitem/Index.vue'
  );

const ShippingAddress = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/shippingaddress/Index.vue'
  );
const ShipmentItem = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/shipitem/Index.vue'
  );
const Shipment = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/shipment/Index.vue'
  );
const ShipmentDetails = () =>
  import(
    /* webpackChunkName: "js/Break type page" */ '@views/admin/shipment/ViewShipment.vue'
  );
// const PhpInfo = () =>
// import(/* webpackChunkName: "js/PhpInfoPage" */ '@/views/admin/PhpInfo.vue');
import { defineAsyncComponent } from 'vue';

import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import roleAdmin from '@/middleware/roleAdmin';
import roleSuperAdmin from '@/middleware/roleSuperAdmin';
import roleUser from '@/middleware/roleUser';

export default [
  {
    path: '/',
    // component: () => Home,
    component: Home,
    name: 'home',
  },
  {
    path: '/about',
    component: About,
    name: 'about',
  },
  {
    path: '/terms',
    component: Terms,
    name: 'terms',
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/admin',
    name: 'adminlayout',
    component: AdminLayout,
    children: [
      {
        path: '',
        name: 'admin',
        component: Admin,
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
      },
      {
        path: 'roles',
        name: 'roles',
        component: Roles,
      },
      {
        path: 'work-report',
        name: 'work-reports',
        component: WorkReport,
      },
      {
        path: 'inventory/items',
        name: 'inventory-items',
        component: InventoryItem,
      },
      {
        path: 'inventories',
        name: 'inventory',
        component: Inventory,
      },
      {
        path: 'inventory/purchases',
        name: 'inventory-purchases',
        component: PurchaseHistory,
      },
      {
        path: 'buyers',
        name: 'buyers',
        component: Buyer,
      },
      {
        path: 'prices',
        name: 'prices',
        component: Price,
      },
      {
        path: 'payments',
        name: 'payments',
        component: Payment,
      },
      {
        path: 'sales',
        name: 'sale',
        component: Sale,
      },
      {
        path: 'sales/:id',
        name: 'sale-details',
        component: SaleDetails,
      },
      {
        path: 'sale/pending',
        name: 'pendingSale',
        component: PendingSale,
      },
      {
        path: 'sale/items',
        name: 'saleitem',
        component: SaleItem,
      },
      {
        path: 'shipping/address',
        name: 'shippingAddress',
        component: ShippingAddress,
      },
      {
        path: 'shipment/items',
        name: 'shipmentItem',
        component: ShipmentItem,
      },
      {
        path: 'shipments',
        name: 'shipment',
        component: Shipment,
      },
      {
        path: 'shipments/:id',
        name: 'shipments-details',
        component: ShipmentDetails,
      },
      {
        path: 'productreturns',
        name: 'productreturns',
        component: ProductReturn,
      },
      {
        path: 'departments',
        name: 'departments',
        component: Department,
      },
      {
        path: 'permissions',
        name: 'permissions',
        component: Permissions,
      },
      {
        path: 'phpinfo',
        name: 'phpinfo',
        component: defineAsyncComponent(() =>
          import('@/views/admin/PhpInfo.vue'),
        ),
      },
      {
        path: 'app-settings',
        name: 'app-settings',
        component: AppSettings,
      },
      {
        path: 'break-types',
        name: 'break-types',
        component: Breaktype,
      },
      {
        path: 'branches',
        name: 'branches',
        component: Branch,
      },
      {
        path: 'attendance',
        name: 'attendance',
        component: Attendance,
      },
      {
        path: 'asset-category',
        name: 'asset-category',
        component: AssetCategory,
      },
      {
        path: 'asset-management',
        name: 'asset-management',
        component: AssetManagement,
      },
      {
        path: 'asset-management/:id',
        name: 'asset-details',
        component: AssetDetails,
      },
      {
        path: 'notices',
        name: 'notice',
        component: Notice,
      },
      {
        path: 'notice/:id',
        name: 'notice-details',
        component: NoticeDetails,
      },
      {
        path: 'holidays',
        name: 'holiday',
        component: Holiday,
      },
      {
        path: 'quotes',
        name: 'quote',
        component: Quote,
      },
      {
        path: 'feedbacks',
        name: 'feedback',
        component: Feedback,
      },
      {
        path: 'leave/application',
        name: 'LeaveApplication',
        component: LeaveApplication,
      },
      {
        path: 'leave/type',
        name: 'LeaveType',
        component: LeaveType,
      },
      {
        path: 'leave/management',
        name: 'Leaves',
        component: LeaveManagement,
      },
      {
        path: 'leave/management/:id',
        name: 'leave-details',
        component: LeaveManagementDetails,
      },
      {
        path: 'leave/request',
        name: 'LeaveRequest',
        component: LeaveRequest,
      },
    ],
    meta: {
      middleware: [roleAdmin],
    },
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgot-password',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/reset-password/:token',
    props: (route) => ({
      token: route.params.token,
      email: route.query.email,
    }),
    component: ResetPassword,
    name: 'reset-password',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/register',
    component: Register,
    name: 'register',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/verify-email/:id/:hash',
    props: (route) => ({
      id: route.params.id,
      hash: route.params.hash,
    }),
    component: VerifyEmail,
    name: 'verify-email',
  },
  {
    path: '/settings',
    component: Settings,
    redirect: {
      name: 'account',
    },
    name: 'settings',
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: 'account',
        component: Account,
        name: 'account',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'profile',
        component: Profile,
        name: 'profile',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'password',
        component: Password,
        name: 'password',
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
];
